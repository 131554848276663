import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { API_REFERAL_URL } from "@common/constants";

import { baseQuery } from "@api/utils";
import { IPagination } from "@common/types";
import {
	IReferal,
	IReferalUsersApi,
	IReferalRewardApi,
	IReferalOferta,
} from "@features/referal/types";

const referalApi = createApi({
	reducerPath: "referalApi",
	baseQuery,
	endpoints: (build) => ({
		getLink: build.query<IReferal, void>({
			query: () => API_REFERAL_URL,
		}),
		getUsers: build.mutation<IReferalUsersApi, IPagination>({
			query: (params) => ({
				url: `${API_REFERAL_URL}/users`,
				method: "get",
				params,
			}),
		}),
		getRewards: build.mutation<IReferalRewardApi, IPagination>({
			query: (params) => ({
				url: `${API_REFERAL_URL}/rewards`,
				method: "get",
				params,
			}),
		}),
		getOferta: build.query<IReferalOferta, void>({
			query: () => `${API_REFERAL_URL}/oferta`,
		}),
	}),
});

export default referalApi;
