import React, { FC, useContext } from "react";
import WithdrawRequestModal from "@components/investor/WithdrawRequestModal/WithdrawRequestModal";
import WithdrawConfirmModal from "@components/investor/WithdrawConfirmModal/WithdrawConfirmModal";
import ProfileVideoModal from "@components/profile/reused/VideoModal/ProfileVideoModal";
import SuccessModal from "@components/common/Modal/SuccessModal";
import { withdrawContext } from "@context/withdrawContext";
import { ProfileMain } from "@components/profile/Main/Main";

const InvestorMain: FC = (props) => {
	const { successModal, setSuccessModal } = useContext(withdrawContext);

	return (
		<ProfileMain>
			{props.children}

			<WithdrawRequestModal />
			<WithdrawConfirmModal />
			<SuccessModal
				message={"Деньги поступят на счет в течение 3-х дней."}
				setVisible={setSuccessModal}
				visible={successModal}
			/>
			<ProfileVideoModal />
		</ProfileMain>
	);
};

export default InvestorMain;
