import React, { FC, useContext } from "react";
import {
	IQualificationAttachment,
	ProfilePagesTitleEnum,
	QualificationAttachmentTypeEnum,
} from "@common/types";
import ProfileContainer from "@components/profile/Views/Container/Container";
import ProfileSection from "@components/profile/Section/ProfileSection";
import styles from "./qualification.module.scss";
import {
	IQualificationContext,
	qualificationContext,
} from "@context/qualificationContext";
import { FormGroupTypes } from "@components/ui/FormGroup/FormGroup.props";
import { Button, FormGroup } from "@components/ui";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { FormProvider, useForm } from "react-hook-form";
import useBoolean from "@hooks/useBoolean";
import ProfileQualificationSection from "@components/investor/Views/Qualification/Components/Section";
import InfoTooltip from "@components/common/InfoTooltip/InfoTooltip";
import classNames from "classnames";
import userApi from "@api/userApi";
import ProfileQualificationChangeConfirm from "@components/investor/Views/Qualification/Components/ChangeConfirmModal";
import { CODES_RESPONSE_SUCCESS } from "@common/constants";
import { InvestorRouteNamesEnum, RouteNamesEnum } from "@app/router";
import { useAppSelector } from "@hooks/useAppSelector";
import { VerificationStatusEnum } from "@features/user/types";
import isEmpty from "lodash.isempty";
import useMounted from "@hooks/useMounted";

interface IQualificationFields {
	[QualificationAttachmentTypeEnum.SECURITY_DOC]: boolean;
	[QualificationAttachmentTypeEnum.EMPLOYMENT_DOC]: boolean;
	[QualificationAttachmentTypeEnum.DEAL_DOC]: boolean;
	[QualificationAttachmentTypeEnum.EDUCATION_DOC]: boolean;
	[QualificationAttachmentTypeEnum.DEPOSIT_DOC]: boolean;
}

const employmentExamples: string[] = [
	"в банке (например, Альфа-Банк, ВТБ, Газпромбанк, Открытие, Сбербанк),",
	"страховой компании (например, АльфаСтрахование, ВСК, Ингосстрах, РЕСО-Гарантия, Росгосстрах),",
	'негосударственном пенсионном фонде (например, НПФ "Открытие", НПФ "Сбербанк", НПФ "Будущее"),',
	"других организациях, являющихся квалифицированным инвестором (например, Альфа-Капитал, Лидер, Регион)",
];

const educationExamples: string[] = [
	'Национальный исследовательский университет "Высшая школа экономики",',
	"Финансовый университет при правительстве Российской Федерации,",
	"МГИМО МИД РОССИИ,",
	"ФГБОУ ВО “РЭУ им. Г.В. Плеханова”,",
	"Национальная ассоциация участников фондового рынка,",
	'АНО ДПО "Учебный центр МФЦ",',
	'Фонд "Институт фондового рынка и управления",',
	"и др.",
];

const depositExamples: string[] = [
	"Вклад или счет в российской кредитной организации или иностранном банке на сумму от 12 млн рублей,",
	"Вклад (депозит) в драгоценных металлах на сумму от 12 млн рублей,",
	"У меня есть ценные бумаги      , в том числе находящиеся в доверительном управлении, на сумму от 12 млн рублей.",
];

const ProfileQualification: FC = () => {
	const {
			isUnqualified,
			setIsUnqualified,
			documents,
			setChangeConfirmModal,
			setQualificationData,
		} = useContext(qualificationContext),
		{ verificationStatus } = useAppSelector(
			(state) => state.user.investorProfile,
		),
		{ data: qualificationStatementData } =
			userApi.useQualificationStatementQuery();
	const formHookMethods = useForm<IQualificationFields>({
		mode: "all",
		defaultValues: {
			[QualificationAttachmentTypeEnum.SECURITY_DOC]: false,
			[QualificationAttachmentTypeEnum.EMPLOYMENT_DOC]: false,
			[QualificationAttachmentTypeEnum.DEAL_DOC]: false,
			[QualificationAttachmentTypeEnum.EDUCATION_DOC]: false,
			[QualificationAttachmentTypeEnum.DEPOSIT_DOC]: false,
		},
	});

	const { value: submitDisabled, setValue: setSubmitDisabled } =
		useBoolean(false);

	const [qualificationRequest, { isLoading: qualificationRequestIsLoading }] =
		userApi.useQualificationRequestMutation();

	const isUnqualifiedCheckboxId = "profileQualificationUnqualified",
		errorMessage = "Для продолжения загрузите документ";
	useMounted({
		onResolve() {
			formHookMethods.reset();
		},
		dependencies: [isUnqualified],
		flag: isUnqualified,
	});

	useMounted({
		onResolve() {
			setSubmitDisabled(
				isEmpty(formHookMethods.formState.dirtyFields) && !isUnqualified,
			);
		},
		dependencies: [formHookMethods.formState],
	});

	const onSubmit = formHookMethods.handleSubmit((formData) => {
		let payload: IQualificationAttachment[] = [];

		for (const key of Object.keys(formData)) {
			const checked = formData[key as keyof IQualificationFields];

			if (checked) {
				const attachmentType = key as keyof IQualificationContext["documents"];
				const attachments = documents[attachmentType];

				if (attachments.length === 0) {
					formHookMethods.setError(attachmentType, { message: errorMessage });
				} else if (attachments.length > 0) {
					payload = [
						...payload,
						...attachments.map(({ uid }) => ({
							type: attachmentType,
							uid,
						})),
					];
				}
			}
		}

		if (isEmpty(formHookMethods.formState.errors)) {
			qualificationRequest({
				attachments: payload,
				withVerification:
					verificationStatus !== VerificationStatusEnum.VERIFIED,
			})
				.unwrap()
				.then((response) => {
					if (response.code === CODES_RESPONSE_SUCCESS)
						setChangeConfirmModal(true);
				});

			setQualificationData(payload);
		}
	});

	return (
		<ProfileContainer title={ProfilePagesTitleEnum.QUALIFICATION}>
			<ProfileSection title={"Квалификация инвестора"} className={styles.quiz}>
				<div className='row'>
					<div className='col-sm-10 col-xl-9'>
						<p className='text-dark pb-4'>
							В соответствии с Федеральным законом № 259-ФЗ физические лица, не
							являющиеся квалифицированными инвесторами, не могут инвестировать
							более 600 000 рублей в год на всех инвестиционных платформах.
						</p>
						<p className='text-dark'>
							Узнайте, можете ли вы инвестировать без указанных ограничений,
							выбрав одно из нижеперечисленных условий:
						</p>
					</div>
				</div>
				<FormProvider {...formHookMethods}>
					<form
						onSubmit={onSubmit}
						onChange={(event: React.ChangeEvent<HTMLFormElement>) => {
							if (
								event.target.id !== isUnqualifiedCheckboxId &&
								event.target.checked
							) {
								setIsUnqualified(false);
							}
						}}
						className={"py-4 mt-3"}>
						<ProfileQualificationSection
							dataKey={QualificationAttachmentTypeEnum.SECURITY_DOC}
							id={"profileQualificationSecurities"}
							labelContent={
								<span className={styles.checkboxLabel}>
									<strong>
										Я владею ценными бумагами на сумму от 12 млн рублей{" "}
									</strong>
									(акции, облигации, форварды, фьючерсы, опционы, свопы).
									<InfoTooltip
										content={
											"Вы превысили лимит в 600 000 рублей за год. Чтобы продолжить инвестировать, Вам необходимо пройти квалификацию."
										}
										className='tooltip'
									/>
								</span>
							}
							noteMessage={
								"Для подтверждения загрузите копию выписки с брокерского счета / выписку из реестров акционеров / по счетам депо."
							}
						/>

						<ProfileQualificationSection
							dataKey={QualificationAttachmentTypeEnum.EMPLOYMENT_DOC}
							id={"profileQualificationEmployment"}
							labelContent={
								<span className={styles.checkboxLabel}>
									<strong>Я официально работал(а) не менее 2х лет:</strong>
									<InfoTooltip
										content={
											"Вы превысили лимит в 600 000 рублей за год. Чтобы продолжить инвестировать, Вам необходимо пройти квалификацию."
										}
										className='tooltip'
									/>
								</span>
							}
							noteMessage={
								"Для подтверждения загрузите заверенную копию трудовой книжки и лицензию соответствующей организации."
							}
							additionalContent={
								<>
									<ul className={"default-marked-list"}>
										{employmentExamples.map((s, i) => (
											<li key={i}>{s}</li>
										))}
									</ul>
									<p className={classNames("pb-3", styles.checkbox)}>
										<strong>
											Я официально работал(а) не менее 3х лет в иных
											организациях,{" "}
										</strong>
										которые совершали операции с ценными бумагами и заключали
										договоры, являющиеся производными финансовыми инструментами.
									</p>
								</>
							}
						/>

						<ProfileQualificationSection
							dataKey={QualificationAttachmentTypeEnum.DEAL_DOC}
							id={"profileQualificationDeals"}
							labelContent={
								<span className={styles.checkboxLabel}>
									<strong>Совершал сделки с ценными бумагами</strong>
									&nbsp;и (или) заключал договоры, являющиеся производными
									финансовыми инструментами, за последние четыре квартала в
									среднем не реже 10 раз в квартал, но не реже одного раза в
									месяц.
									<strong>
										{" "}
										При этом совокупная цена таких сделок (договоров) составляет
										не менее 6 млн рублей.
									</strong>
								</span>
							}
							noteMessage={
								"Для подтверждения загрузите выписку по брокерским счетам или справку брокера."
							}
						/>

						<ProfileQualificationSection
							dataKey={QualificationAttachmentTypeEnum.EDUCATION_DOC}
							id={"profileQualificationEducation"}
							labelContent={
								<span className={styles.checkboxLabel}>
									<strong>
										У меня есть диплом о высшем экономическом образовании,{" "}
									</strong>
									выданный организацией высшего профессионального образования,
									которая на момент выдачи указанного документа осуществляла
									аттестацию граждан в сфере профессиональной деятельности на
									рынке ценных бумаг, например:
								</span>
							}
							noteMessage={
								"Для подтверждения загрузите копию диплома / сертификата"
							}
							additionalContent={
								<>
									<ul className={"default-marked-list"}>
										{educationExamples.map((s, i) => (
											<li key={i}>{s}</li>
										))}
									</ul>
									<p className={"pb-2"}>
										<strong>У меня есть сертификат</strong> CFA, CIIA, FRM,
										квалификационный аттестат специалиста финансового рынка,
										аудитора, страхового актуария.
									</p>
								</>
							}
						/>

						<ProfileQualificationSection
							dataKey={QualificationAttachmentTypeEnum.DEPOSIT_DOC}
							id={"profileQualificationDeposit"}
							labelContent={
								<span className={styles.checkboxLabel}>
									<strong>У меня есть:</strong>
								</span>
							}
							noteMessage={
								"Для подтверждения загрузите выписку по банковскому или брокерскому счету, выписку реестродержателей и депозитариев, справку от брокера."
							}
							additionalContent={
								<ul className={"default-marked-list"}>
									{depositExamples.map((s, i) => (
										<li key={i}>{s}</li>
									))}
								</ul>
							}
						/>

						<div className={styles.section}>
							<FormGroup
								id={isUnqualifiedCheckboxId}
								fieldType={FormGroupTypes.CHECKBOX}
								className={styles.checkbox}
								checked={isUnqualified}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
									setIsUnqualified(event.target.checked)
								}
								label={
									<strong className={styles.checkboxLabel}>
										Я не отвечаю ни одному из перечисленных требований
									</strong>
								}
							/>
						</div>

						<div className='row g-4 pt-md-5 mt-3 align-items-center'>
							<div className='col-auto'>
								<Button
									variant={ButtonVariant.outlined}
									type={"button"}
									as={ButtonTypesEnum.LINK}
									to={RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.DATA}>
									Отменить
								</Button>
							</div>
							<div className='col-auto'>
								<Button
									variant={ButtonVariant.contained}
									as={ButtonTypesEnum.BUTTON}
									type={"submit"}
									disabled={submitDisabled}
									isLoading={qualificationRequestIsLoading}>
									Отправить
								</Button>
							</div>
							<div className='col-sm-5 col-xl-4 col-lt-3'>
								{!isEmpty(formHookMethods.formState.errors) ? (
									<small className='text-danger'>Не все поля заполнены</small>
								) : (
									qualificationStatementData?.url &&
									isUnqualified && (
										<small className='text-dark'>
											Нажимая на кнопку “Отправить”, вы подписываете{" "}
											<a
												className='text-primary text-decoration-underline'
												rel='noreferrer'
												href={qualificationStatementData.url}
												target={"_blank"}>
												{qualificationStatementData.title}
											</a>
										</small>
									)
								)}
							</div>
						</div>
					</form>
				</FormProvider>
			</ProfileSection>
			<ProfileQualificationChangeConfirm />
		</ProfileContainer>
	);
};

export default ProfileQualification;
