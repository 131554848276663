import { IAttachment } from "@common/types";

export interface IUtilsState {
	sections: ISection[];
}

export enum SectionsCodeEnum {
	ACCOUNT = "account",
	PROJECTS = "projects",
	INVESTOR_CLUB = "investor-club",
	QUESTIONS = "questions",
	ABOUT = "about",
	DOCUMENTS = "documents",
	REFERAL = "referal",
}

export enum BorrowerSectionsCodeEnum {
	ACCOUNT = "account",
	CABINET = "cabinet",
}

export interface IDocument {
	attachment?: IAttachment | null;
	documentId: string;
	type: string;
	link: string;
	title: string;
}

export interface ISection {
	code: SectionsCodeEnum | BorrowerSectionsCodeEnum;
	title: string;
	enabled: boolean;
	description: string;
}
