import {
	ButtonIconsVariant,
	ButtonProps,
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import React from "react";
import { Spinner } from "react-bootstrap";
import { ReactComponent as ArrowRightIcon } from "@icons/arrow-right.svg";
import { ReactComponent as LogoutIcon } from "@icons/logout.svg";
import { ReactComponent as NotifyIcon } from "@icons/notification.svg";
import { ReactComponent as ExclamationIcon } from "@icons/exclamation.svg";
import { ReactComponent as ArrowUpIcon } from "@icons/arrow-up.svg";
import { ReactComponent as CopyIcon } from "@icons/copy.svg";
import styles from "./button.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";

const Button = (props: ButtonProps): JSX.Element => {
	const classesGroup = classNames(
		"button",
		styles.root,
		styles[props.variant],
		props.className,
		{ "is-loading": props.isLoading },
	);

	const generateIcon = (): JSX.Element => {
		switch (props.icon) {
			case ButtonIconsVariant.logout:
				return <LogoutIcon />;
			case ButtonIconsVariant.notify:
				return <NotifyIcon />;
			case ButtonIconsVariant.arrowRight:
				return <ArrowRightIcon />;
			case ButtonIconsVariant.arrowUp:
				return <ArrowUpIcon />;
			case ButtonIconsVariant.copy:
				return <CopyIcon />;
			default:
				return <></>;
		}
	};
	const generateWrap = (inner: React.ReactNode): JSX.Element => {
		if (props.as === ButtonTypesEnum.BUTTON) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const {
				as,
				className,
				isLoading,
				disabled,
				variant,
				icon,
				wrapperClassName,
				...rest
			} = props;

			return (
				<button
					{...rest}
					disabled={isLoading || disabled}
					className={classesGroup}>
					{inner}
				</button>
			);
		} else if (props.as === ButtonTypesEnum.EXTERNAL) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { as, className, isLoading, variant, wrapperClassName, ...rest } =
				props;

			return (
				<a
					target='_blank'
					rel='noopener noreferrer'
					className={classesGroup}
					{...rest}>
					{inner}
				</a>
			);
		} else if (props.as === ButtonTypesEnum.LINK) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { as, className, isLoading, variant, wrapperClassName, ...rest } =
				props;

			return (
				<Link className={classesGroup} {...rest}>
					{inner}
				</Link>
			);
		} else {
			return <></>;
		}
	};

	const { icon, children } = props;
	return (
		<div
			className={classNames(
				props.wrapperClassName || "d-block position-relative",
			)}>
			{generateWrap(
				<>
					{props.isLoading && (
						<Spinner
							size={"sm"}
							className={"spinner--centered"}
							animation='border'
						/>
					)}
					<span
						style={{
							opacity: props.isLoading ? "0" : undefined,
							visibility: props.isLoading ? "hidden" : undefined,
							pointerEvents: props.isLoading ? "none" : undefined,
						}}
						className={styles.content}>
						{props.variant === ButtonVariant.danger && (
							<ExclamationIcon className={"exclamation-icon"} />
						)}
						{Boolean(icon) ? (
							<>
								{Boolean(children) && (
									<span className={"me-2 pe-1"}>{children}</span>
								)}
								{generateIcon()}
							</>
						) : (
							children
						)}
					</span>
				</>,
			)}
		</div>
	);
};

export default Button;
