import React, { FC, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import Table from "@components/common/Table/Table";
import { PaymentStatus } from "@components/common/PaymentStatus";
import ProfileLoader from "@components/common/ProfileLoader";
import { HeadingVariant } from "@components/ui/Heading/Heading.props";
import { Heading } from "@components/ui";

import { IReferalReward, IReferalRewardApi } from "@features/referal/types";
import referalApi from "@api/referalApi";
import { Spinner } from "react-bootstrap";

import { usePagination } from "@hooks/usePagination";
import styles from "./referal.module.scss";
import {
	SEARCH_LIMIT,
	CUSTOM_EVENT__RESET_REFERAL_REWARD_PAGINATION_OFFSET,
} from "@common/constants";
import useMounted from "@hooks/useMounted";
import { formatDate } from "@common/utils";
import { useCustomEvent } from "@hooks/customEvent";

export const RewardList: FC = () => {
	const [rewards, setRewards] = useState<IReferalReward[]>([]);
	const {
		setDefaultPaginationOffset,
		resetPaginationOffset,
		increasePaginationOffset,
		paginationOffset,
		setPaginationCount,
		showUploadMoreButton,
	} = usePagination(rewards);
	const [getRewards, getRewardsState] = referalApi.useGetRewardsMutation();

	useCustomEvent({
		eventName: CUSTOM_EVENT__RESET_REFERAL_REWARD_PAGINATION_OFFSET,
		onSignal() {
			resetPaginationOffset();
		},
	});

	useMounted({
		onResolve(isMounted) {
			getRewards({ offset: paginationOffset, limit: SEARCH_LIMIT })
				.unwrap()
				.then((response: IReferalRewardApi) => {
					if (isMounted) {
						setRewards([...rewards, ...response.items]);
						setPaginationCount(response.count);
						setDefaultPaginationOffset();
					}
				});
		},
		onDestroy() {
			setRewards([]);
		},
	});

	return (
		<div className={styles.blockItem}>
			<Heading
				tag={"h3"}
				className={classNames(styles.heading, "mb-0")}
				variant={HeadingVariant.middle}>
				Реферальные вознаграждения
			</Heading>
			<Table
				className={styles.table}
				emptyMessage={"Вознаграждений пока что нет"}
				horizontallyScrolled
				thCells={["Дата", "Сумма", "ID", "ФИО реферала", "Статус перевода"]}
				tdCells={rewards.map(
					({
						createdAt,
						amount,
						referralRewardId,
						idProfile,
						firstName,
						lastName,
						middleName,
						status,
					}) => {
						const name = `${lastName || ""} ${firstName || ""} ${
							middleName || ""
						}`;
						return [
							formatDate(createdAt) || "-",
							amount ? (
								<>
									<NumberFormat
										title={amount.toString()}
										value={Number(amount)}
										displayType={"text"}
										thousandSeparator={" "}
										suffix={" "}
										fixedDecimalScale
									/>
									<span> &#x20bd;</span>
								</>
							) : (
								"-"
							),
							idProfile ? String(idProfile) : "-",
							name.trim().length ? name : "-",
							<PaymentStatus key={referralRewardId}>{status}</PaymentStatus>,
						];
					},
				)}
			/>
			<ProfileLoader
				isError={getRewardsState.isError}
				isLoading={getRewardsState.isLoading}
				errorMessage={"Ошибка при попытке загрузки списка вознаграждений."}
				onClick={() => {
					getRewards({
						offset: paginationOffset,
						limit: SEARCH_LIMIT,
					})
						.unwrap()
						.then((response: IReferalRewardApi) => {
							setRewards([...rewards, ...response.items]);
							setPaginationCount(response.count);
							increasePaginationOffset();
						});
				}}
				needToShown={showUploadMoreButton}
			/>
		</div>
	);
};
