import React, { FC, useEffect, useState } from "react";

import classNames from "classnames";
import Table from "@components/common/Table/Table";

import ProfileLoader from "@components/common/ProfileLoader";
import { HeadingVariant } from "@components/ui/Heading/Heading.props";
import { Heading } from "@components/ui";

import { IReferalUser, IReferalUsersApi } from "@features/referal/types";
import referalApi from "@api/referalApi";
import { Spinner } from "react-bootstrap";

import { usePagination } from "@hooks/usePagination";
import styles from "./referal.module.scss";
import {
	SEARCH_LIMIT,
	CUSTOM_EVENT__RESET_REFERAL_USERS_PAGINATION_OFFSET,
} from "@common/constants";
import useMounted from "@hooks/useMounted";
import { formatDate } from "@common/utils";
import { useCustomEvent } from "@hooks/customEvent";

export const UserList: FC = () => {
	const [users, setUsers] = useState<IReferalUser[]>([]);
	const {
		setDefaultPaginationOffset,
		resetPaginationOffset,
		increasePaginationOffset,
		paginationOffset,
		setPaginationCount,
		showUploadMoreButton,
	} = usePagination(users);

	const [getUsers, getUsersState] = referalApi.useGetUsersMutation();

	useCustomEvent({
		eventName: CUSTOM_EVENT__RESET_REFERAL_USERS_PAGINATION_OFFSET,
		onSignal() {
			resetPaginationOffset();
		},
	});

	useMounted({
		onResolve(isMounted) {
			getUsers({ offset: paginationOffset, limit: SEARCH_LIMIT })
				.unwrap()
				.then((response: IReferalUsersApi) => {
					if (isMounted) {
						setUsers([...users, ...response.items]);
						setPaginationCount(response.count);
						setDefaultPaginationOffset();
					}
				});
		},
		onDestroy() {
			setUsers([]);
		},
	});

	return (
		<div className={classNames(styles.blockItem, styles.blockItemShort)}>
			<Heading
				tag={"h3"}
				className={classNames(styles.heading, "mb-0")}
				variant={HeadingVariant.middle}>
				Список рефералов
			</Heading>
			<Table
				className={styles.table}
				emptyMessage={"Рефералов пока что нет"}
				horizontallyScrolled
				thCells={["Дата регистрации", "ID", "ФИО", "Тип реферала"]}
				tdCells={users.map(
					({
						dateJoined,
						id,
						firstName,
						lastName,
						middleName,
						typeReferral,
					}) => {
						const name = `${lastName || ""} ${firstName || ""} ${
							middleName || ""
						}`;
						return [
							formatDate(dateJoined) || "-",
							id ? String(id) : "-",
							name.trim().length ? name : "-",
							typeReferral || "-",
						];
					},
				)}
			/>
			<ProfileLoader
				isError={getUsersState.isError}
				isLoading={getUsersState.isLoading}
				errorMessage={"Ошибка при попытке загрузки списка рефералов."}
				onClick={() => {
					getUsers({ offset: paginationOffset, limit: SEARCH_LIMIT })
						.unwrap()
						.then((response: IReferalUsersApi) => {
							setUsers([...users, ...response.items]);
							setPaginationCount(response.count);
							increasePaginationOffset();
						});
				}}
				needToShown={showUploadMoreButton}
			/>
		</div>
	);
};
