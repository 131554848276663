import React, { FC, useContext, useEffect } from "react";
import { ProfileAside } from "@components/profile/Aside/Aside";
import borrowerApi from "@api/borrowerApi";
import { useAppActions } from "@hooks/useAppActions";
import {
	BorrowerProfileKeysEnum,
	UserDataKeysEnum,
} from "@features/user/types";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { Button } from "@components/ui";
import { Fade } from "@components/common/Transitions/Fade";
import ProfileNav from "@components/profile/Nav/ProfileNav";
import { IProfileNavigationItem } from "@common/types";
import { BorrowerRouteNamesEnum, RouteNamesEnum } from "@app/router";
import {
	BorrowerSectionsCodeEnum,
	ISection,
	SectionsCodeEnum,
} from "@features/utils/types";
import {
	AccountIcon,
	ChatIcon,
	UsersIcon,
} from "@components/profile/Nav/icons";
import { matchPath, useLocation, useHistory } from "react-router-dom";
import BorrowerPersonalManager from "@components/borrower/PersonalManager/PersonalManager";
import { useAppSelector } from "@hooks/useAppSelector";
import { borrowerContext } from "@context/borrowerContext";
import { IRedirectToBorrowerConditionsState } from "@context/profileContext";
import AsideReverseEntityLink from "@components/profile/Aside/ReversEntityLink/ReverseEntityLink";

enum NavNamesEnum {
	ACCOUNT = "Учетная запись",
	CABINET = "Кабинет",
	QUESTIONS = "Вопросы",
}

export const BORROWER_NAVIGATION: IProfileNavigationItem[] = [
	{
		Icon: AccountIcon,
		name: NavNamesEnum.ACCOUNT,
		path: RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.ACCOUNT,
		code: BorrowerSectionsCodeEnum.ACCOUNT,
	},
	{
		Icon: UsersIcon,
		name: NavNamesEnum.CABINET,
		path: RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.CABINET,
		code: BorrowerSectionsCodeEnum.CABINET,
	},
	{
		Icon: ChatIcon,
		name: NavNamesEnum.QUESTIONS,
		path: RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.QUESTIONS,
		code: SectionsCodeEnum.QUESTIONS,
	},
];

export const BorrowerAside: FC = () => {
	const {
			data: profileData,
			isLoading: profileDataIsLoading,
			isError: profileDataIsError,
		} = borrowerApi.useGetProfileQuery(),
		{ setUserData, setSections, setManager, setRole } = useAppActions(),
		[getManager, { data: managerData }] = borrowerApi.useLazyGetManagerQuery(),
		location = useLocation(),
		history = useHistory(),
		{ borrowerManager } = useAppSelector((state) => state.user),
		{ setProfileIsLoading } = useContext(borrowerContext);
	useEffect(() => {
		setProfileIsLoading(profileDataIsLoading);
	}, [profileDataIsLoading]);

	useEffect(() => {
		if (profileData) {
			setUserData({
				data: profileData,
				key: UserDataKeysEnum.BP,
			});

			if (profileData.role) {
				setRole(profileData.role);
			}
		}

		if (
			profileData &&
			profileData.hasOwnProperty(BorrowerProfileKeysEnum.MANAGER_ID) &&
			Boolean(profileData[BorrowerProfileKeysEnum.MANAGER_ID])
		) {
			getManager(profileData[BorrowerProfileKeysEnum.MANAGER_ID]);
		}
	}, [profileData]);

	useEffect(() => {
		managerData && setManager(managerData);
	}, [managerData]);

	useEffect(() => {
		setSections(
			BORROWER_NAVIGATION.map(({ code, name }) => {
				return {
					title: name,
					enabled: true,
					description: "",
					code,
				} as ISection;
			}),
		);
	}, []);

	return (
		<ProfileAside
			userDataIsLoading={profileDataIsLoading}
			isLoadingError={profileDataIsError}
			toPersonalDataLink={{
				inProp:
					matchPath(RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.ACCOUNT, {
						path: location.pathname,
						exact: false,
					}) === null,
				to: RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.ACCOUNT,
			}}>
			<Fade inProp={!profileDataIsLoading && !profileDataIsError}>
				<div className='py-4 mt-3'>
					<Button
						as={ButtonTypesEnum.BUTTON}
						variant={ButtonVariant.success}
						type={"button"}
						className={"w-100 mb-4"}
						onClick={() => {
							history.push(
								RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.ACCOUNT,
								{
									needRedirect: true,
								} as IRedirectToBorrowerConditionsState,
							);
						}}>
						Получить займ
					</Button>
					<ProfileNav className={"pt-2"} items={BORROWER_NAVIGATION} />
				</div>
			</Fade>

			<Fade inProp={Boolean(borrowerManager)}>
				<BorrowerPersonalManager className={"mt-3"} />
			</Fade>

			<Fade inProp={!profileDataIsLoading && !profileDataIsError}>
				<div className='my-auto pt-5 pb-4 colgroup'>
					<AsideReverseEntityLink
						title={"О платформе"}
						to={`${process.env.REACT_APP_ROOT_LANDING_URL}about.html`}
						external
					/>
					<AsideReverseEntityLink
						title={"Документы"}
						to={`${process.env.REACT_APP_ROOT_LANDING_URL}documents.html`}
						external
					/>
					<AsideReverseEntityLink
						title={"Стать инвестором"}
						to={`${process.env.REACT_APP_ROOT_LANDING_URL}investor.html`}
						external
					/>
				</div>
			</Fade>
		</ProfileAside>
	);
};
