import React, { FC } from "react";
import styles from "@components/common/PaymentStatus/paymentStatus.module.scss";

import classNames from "classnames";

export enum PaymentStatusName {
	pending = "Ожидает перевода",
	success = "Перевод выполнен",
}

interface IPaymentStatus {
	children: PaymentStatusName;
	className?: string;
}

const PaymentStatusVariant = {
	[PaymentStatusName.pending]: "pending",
	[PaymentStatusName.success]: "success",
};

export const PaymentStatus: FC<IPaymentStatus> = ({ className, children }) => {
	return (
		<div
			className={classNames(
				styles.wrapper,
				styles[PaymentStatusVariant[children as PaymentStatusName]],
				className,
			)}>
			{children}
		</div>
	);
};
