import { useEffect, useState } from "react";
import { IAsideInfoSource, UserDataKeysEnum } from "@features/user/types";
import { useAppSelector } from "@hooks/useAppSelector";
import { AuthRolesEnum } from "@features/auth/types";

type InfoKeyType = keyof IAsideInfoSource | null;

export const useUserInfoKey = (): InfoKeyType => {
	const { role } = useAppSelector((state) => state.auth),
		[userInfoKey, setUserInfoKey] = useState<InfoKeyType>(null);
	useEffect(() => {
		switch (role) {
			case AuthRolesEnum.INVESTOR:
				setUserInfoKey(UserDataKeysEnum.II);
				break;
			case AuthRolesEnum.BORROWER:
				setUserInfoKey(UserDataKeysEnum.BP);
				break;
		}
	}, [role]);

	return userInfoKey;
};
