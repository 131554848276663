import React, { FC } from "react";

interface IIconMainProps {
	width: number;
	height: number;
}

const IconMain: FC<IIconMainProps> = ({
	width,
	height,
	...props
}): JSX.Element => (
	<svg
		width={width}
		height={height}
		viewBox={`0 0 ${width} ${height}`}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		{props.children}
	</svg>
);

const ProfileIcon: FC = (): JSX.Element => (
	<IconMain width={24} height={24}>
		<path
			className={"path path--active"}
			d='M12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739ZM12 2C14.9391 2 17.294 4.35402 17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2Z'
			fill='#20AAFF'
		/>
		<path
			className={"path path--default"}
			d='M9.83007 14.8209C11.2738 14.6935 12.726 14.6935 14.1697 14.8209L14.6307 14.8599C15.2612 14.922 15.8873 15.0194 16.5034 15.1509C18.0737 15.4744 19.214 16.0483 19.7188 17.1127C20.0938 17.9047 20.0938 18.8241 19.7186 19.6164C19.2126 20.6836 18.0576 21.2623 16.5151 21.5666L16.0478 21.6636C15.4231 21.7835 14.7932 21.8653 14.1675 21.9081C12.9934 22.0081 11.8139 22.0263 10.676 21.9637C10.5506 21.9637 10.4664 21.9633 10.3922 21.9615L10.248 21.9561C10.0726 21.9472 9.91191 21.9288 9.84448 21.909C9.05286 21.8553 8.2665 21.7407 7.51215 21.5705L7.25626 21.5171C5.82782 21.1997 4.76996 20.6271 4.28064 19.6153C4.09532 19.2227 3.99946 18.7935 4.00002 18.3627C3.99841 17.9288 4.09505 17.5002 4.28726 17.0999C4.79687 16.0763 5.9942 15.4604 7.49082 15.1521C8.26623 14.9859 9.05235 14.8749 9.83007 14.8209ZM14.0436 16.263C12.6837 16.143 11.316 16.143 9.94296 16.264C9.21964 16.3144 8.50075 16.4159 7.78607 16.569C6.67921 16.7971 5.83295 17.2324 5.5812 17.7379C5.4885 17.931 5.44075 18.1428 5.44154 18.3609C5.44127 18.5803 5.48966 18.797 5.58026 18.989C5.83917 19.5243 6.63773 19.9244 7.80777 20.1537L8.16029 20.2283C8.75068 20.3454 9.35874 20.4261 10.0345 20.4771C10.1198 20.4942 10.2119 20.5048 10.3212 20.5103L10.7148 20.5171C11.825 20.5771 12.938 20.5599 14.0576 20.4647C14.7792 20.4152 15.496 20.3112 16.2195 20.1498L16.4445 20.1021C17.472 19.8691 18.183 19.4879 18.4169 18.9944C18.6057 18.5958 18.6057 18.133 18.4171 17.7348C18.1667 17.2067 17.3751 16.8083 16.2087 16.568C15.4991 16.4165 14.7799 16.3149 14.0563 16.264L14.0436 16.263ZM12.0043 2C14.9383 2 17.3168 4.38864 17.3168 7.33517C17.3168 10.2817 14.9383 12.6703 12.0043 12.6703C9.07027 12.6703 6.69177 10.2817 6.69177 7.33517C6.69177 4.38864 9.07027 2 12.0043 2ZM12.0043 3.44767C9.8664 3.44767 8.1333 5.18816 8.1333 7.33517C8.1333 9.48218 9.8664 11.2227 12.0043 11.2227C14.1422 11.2227 15.8753 9.48218 15.8753 7.33517C15.8753 5.18816 14.1422 3.44767 12.0043 3.44767Z'
			fill='#96A6B8'
		/>
	</IconMain>
);

const DocumentIcon: FC = (): JSX.Element => (
	<IconMain width={23} height={23}>
		<path
			className={"path path--active"}
			d='M16.191 2C19.28 2 21 3.78 21 6.83V17.16C21 20.26 19.28 22 16.191 22H7.81C4.77 22 3 20.26 3 17.16V6.83C3 3.78 4.77 2 7.81 2H16.191ZM8.08 15.74C7.78 15.71 7.49 15.85 7.33 16.11C7.17 16.36 7.17 16.69 7.33 16.95C7.49 17.2 7.78 17.35 8.08 17.31H15.92C16.319 17.27 16.62 16.929 16.62 16.53C16.62 16.12 16.319 15.78 15.92 15.74H8.08ZM15.92 11.179H8.08C7.649 11.179 7.3 11.53 7.3 11.96C7.3 12.39 7.649 12.74 8.08 12.74H15.92C16.35 12.74 16.7 12.39 16.7 11.96C16.7 11.53 16.35 11.179 15.92 11.179ZM11.069 6.65H8.08V6.66C7.649 6.66 7.3 7.01 7.3 7.44C7.3 7.87 7.649 8.22 8.08 8.22H11.069C11.5 8.22 11.85 7.87 11.85 7.429C11.85 7 11.5 6.65 11.069 6.65Z'
			fill='#20AAFF'
		/>
		<path
			className={"path path--default"}
			d='M15.7378 1.91669C18.9338 1.91669 20.8448 3.83669 20.8748 7.04869V16.7077C20.8748 19.9647 18.9588 21.9167 15.7378 21.9167H8.01276C7.60076 21.9167 7.20976 21.8847 6.84176 21.8217L6.62376 21.7807C4.25276 21.2877 2.87476 19.4717 2.87476 16.7077V7.12569C2.87476 6.79169 2.89476 6.47169 2.93476 6.16569C3.28376 3.48069 5.12876 1.91669 8.01276 1.91669H15.7378ZM15.7288 3.37369H8.01276C5.55676 3.37369 4.31176 4.63669 4.31176 7.12569V16.7077C4.31176 19.1967 5.55676 20.4597 8.01276 20.4597H15.7288C18.1838 20.4597 19.4208 19.1967 19.4208 16.7077V7.12569C19.4208 4.63669 18.1838 3.37369 15.7288 3.37369ZM15.4678 15.4267C15.8618 15.4267 16.1808 15.7507 16.1808 16.1507C16.1808 16.5507 15.8618 16.8747 15.4678 16.8747H8.25676C7.86276 16.8747 7.54276 16.5507 7.54276 16.1507C7.54276 15.7507 7.86276 15.4267 8.25676 15.4267H15.4678ZM15.4678 11.1877C15.7428 11.1577 16.0118 11.2897 16.1588 11.5267C16.3058 11.7657 16.3058 12.0677 16.1588 12.3067C16.0118 12.5437 15.7428 12.6757 15.4678 12.6457H8.25676C7.89076 12.6037 7.61376 12.2907 7.61376 11.9167C7.61376 11.5427 7.89076 11.2287 8.25676 11.1877H15.4678ZM11.0088 6.95869C11.3748 6.99969 11.6518 7.31369 11.6518 7.68769C11.6518 8.06069 11.3748 8.37469 11.0088 8.41569H8.26576C7.89876 8.37469 7.62176 8.06069 7.62176 7.68769C7.62176 7.31369 7.89876 6.99969 8.26576 6.95869H11.0088Z'
			fill='#96A6B8'
		/>
	</IconMain>
);

const UsersIcon: FC = (): JSX.Element => (
	<IconMain width={23} height={24}>
		<path
			className={"path path--active"}
			d='M9.30642 15.1062C13.2476 15.1062 16.6128 15.731 16.6128 18.2314C16.6128 20.7306 13.2692 21.3782 9.30642 21.3782C5.36523 21.3782 2 20.7534 2 18.2541C2 15.7538 5.34364 15.1062 9.30642 15.1062ZM15.6079 13.6006C17.1147 13.5727 18.7345 13.7796 19.3331 13.9265C20.6012 14.1758 21.4354 14.6848 21.7809 15.4244C22.073 16.0317 22.073 16.7361 21.7809 17.3423C21.2523 18.4896 19.5481 18.8578 18.8857 18.953C18.7489 18.9737 18.6389 18.8547 18.6533 18.7171C18.9917 15.5382 16.3001 14.031 15.6038 13.6844C15.574 13.6689 15.5678 13.6451 15.5709 13.6306C15.5729 13.6203 15.5853 13.6038 15.6079 13.6006ZM9.30642 3C11.9898 3 14.1414 5.16205 14.1414 7.86202C14.1414 10.561 11.9898 12.7251 9.30642 12.7251C6.62308 12.7251 4.47147 10.561 4.47147 7.86202C4.47147 5.16205 6.62308 3 9.30642 3ZM15.8702 3.81082C18.462 3.81082 20.4974 6.26355 19.8042 8.99559C19.3362 10.8349 17.6423 12.0566 15.755 12.0069C15.5657 12.0018 15.3796 11.9842 15.1996 11.9531C15.069 11.9304 15.0032 11.7825 15.0772 11.6728C15.7972 10.6073 16.2075 9.32559 16.2075 7.94974C16.2075 6.51389 15.7591 5.17529 14.9805 4.07771C14.9558 4.04358 14.9373 3.99082 14.962 3.95151C14.9826 3.91944 15.0206 3.90289 15.0566 3.89461C15.3189 3.84082 15.5884 3.81082 15.8702 3.81082Z'
			fill='#20AAFF'
		/>
		<path
			className={"path path--default"}
			d='M9.50838 14.0294L9.82817 14.0308C14.4728 14.0698 17.1006 14.9743 17.1006 17.445C17.1006 19.8633 14.5821 20.7962 10.1405 20.8744L9.50838 20.8798C4.66387 20.8798 1.91711 19.9918 1.91711 17.4643C1.91711 14.9334 4.6748 14.0294 9.50838 14.0294ZM9.50838 15.4759C5.49089 15.4759 3.41717 16.1557 3.41717 17.4643C3.41717 18.7646 5.48591 19.4334 9.50838 19.4334C13.5262 19.4334 15.6006 18.7535 15.6006 17.445C15.6006 16.1454 13.53 15.4759 9.50838 15.4759ZM17.5043 13.3466C18.0849 13.3846 18.6621 13.4649 19.2168 13.5835C20.405 13.8114 21.2883 14.2368 21.693 15.0556C21.9918 15.6619 21.9918 16.3672 21.693 16.9734C21.2906 17.7902 20.4164 18.2125 19.2216 18.4495C18.8159 18.53 18.4193 18.2781 18.3359 17.8868C18.2524 17.4956 18.5137 17.1132 18.9194 17.0327C19.6883 16.8802 20.2 16.633 20.3382 16.3526C20.4433 16.1392 20.4433 15.8899 20.3385 15.6772C20.1994 15.3958 19.685 15.1481 18.9084 14.9989C18.411 14.8927 17.9081 14.8228 17.4026 14.7897C16.9893 14.7626 16.677 14.4175 16.7052 14.019C16.7333 13.6205 17.0911 13.3195 17.5043 13.3466ZM9.50838 2.49982C12.3565 2.49982 14.6436 4.70479 14.6436 7.45052C14.6436 10.197 12.3567 12.4022 9.50838 12.4022C6.6602 12.4022 4.3742 10.1972 4.3742 7.45052C4.3742 4.70465 6.66038 2.49982 9.50838 2.49982ZM15.9373 3.53123C18.1723 3.53123 19.9844 5.279 19.9844 7.43374C19.9844 9.58915 18.1726 11.3363 15.9373 11.3363C15.5231 11.3363 15.1873 11.0125 15.1873 10.613C15.1873 10.2136 15.5231 9.88981 15.9373 9.88981C17.3441 9.88981 18.4844 8.7903 18.4844 7.43374C18.4844 6.07778 17.3438 4.97768 15.9373 4.97768C15.5231 4.97768 15.1873 4.65388 15.1873 4.25445C15.1873 3.85503 15.5231 3.53123 15.9373 3.53123ZM9.50838 3.94626C7.48889 3.94626 5.87425 5.50345 5.87425 7.45052C5.87425 9.39843 7.48877 10.9557 9.50838 10.9557C11.5282 10.9557 13.1435 9.3982 13.1435 7.45052C13.1435 5.50369 11.5281 3.94626 9.50838 3.94626Z'
			fill='#96A6B8'
		/>
	</IconMain>
);

const ChatIcon: FC = (): JSX.Element => (
	<IconMain width={24} height={24}>
		<path
			className={"path path--active"}
			d='M22 11.985C22 6.65699 17.7 2 12.02 2C6.21 2 2 6.74712 2 12.015C2 13.6975 2.49 15.4301 3.35 17.0125C3.51 17.2729 3.53 17.6034 3.42 17.9139L2.75 20.1572C2.6 20.698 3.06 21.0986 3.57 20.9384L5.59 20.3375C6.14 20.1572 6.57 20.3876 7.08 20.698C8.54 21.5593 10.36 22 12 22C16.96 22 22 18.1642 22 11.985Z'
			fill='#20AAFF'
		/>
		<path
			className={"path path--active"}
			d='M11.2026 14.1626C11.2026 13.5701 11.2746 13.0983 11.4185 12.7471C11.5623 12.3958 11.8247 12.0509 12.2056 11.7124C12.5907 11.3696 12.8467 11.0924 12.9736 10.8809C13.1006 10.665 13.1641 10.4386 13.1641 10.2017C13.1641 9.48649 12.834 9.12891 12.1738 9.12891C11.8607 9.12891 11.6089 9.22624 11.4185 9.4209C11.2323 9.61133 11.1349 9.87581 11.1265 10.2144H9.28564C9.29411 9.40609 9.55436 8.77344 10.0664 8.31641C10.5827 7.85938 11.2852 7.63086 12.1738 7.63086C13.071 7.63086 13.7671 7.8488 14.2622 8.28467C14.7573 8.71631 15.0049 9.3278 15.0049 10.1191C15.0049 10.4788 14.9245 10.8195 14.7637 11.1411C14.6029 11.4585 14.3215 11.8118 13.9194 12.2012L13.4053 12.6899C13.0837 12.9989 12.8996 13.3607 12.853 13.7754L12.8276 14.1626H11.2026ZM11.0186 16.1113C11.0186 15.8278 11.1138 15.5951 11.3042 15.4131C11.4989 15.2269 11.7464 15.1338 12.0469 15.1338C12.3473 15.1338 12.5928 15.2269 12.7832 15.4131C12.9779 15.5951 13.0752 15.8278 13.0752 16.1113C13.0752 16.3906 12.98 16.6213 12.7896 16.8032C12.6034 16.9852 12.3558 17.0762 12.0469 17.0762C11.738 17.0762 11.4883 16.9852 11.2979 16.8032C11.1117 16.6213 11.0186 16.3906 11.0186 16.1113Z'
			fill='white'
		/>
		<path
			className={"path path--default"}
			d='M12.058 2.00008C16.2115 2.03077 19.9153 4.61803 21.3675 8.50387C22.8199 12.3901 21.7187 16.7681 18.5997 19.5079C15.4812 22.2473 10.9929 22.7804 7.31788 20.848L7.27208 20.8319C7.26493 20.8278 7.26378 20.8242 7.26224 20.8194L6.57971 20.4332C6.4407 20.3763 6.28665 20.3671 6.19261 20.3909C5.4831 20.646 4.75798 20.8554 4.02167 21.018L3.88671 21.0343C3.07616 21.0523 2.66776 20.5263 2.66776 19.7609L2.6873 19.597C2.87136 18.8357 3.10204 18.0865 3.36458 17.3927C3.40531 17.2613 3.39175 17.1192 3.32147 16.9875L3.13765 16.6292C1.51288 13.5227 1.63541 9.79358 3.46059 6.8001C5.28554 3.80701 8.54635 1.98613 12.058 2.00008ZM12.0484 3.39463L11.7675 3.39903C8.85494 3.48236 6.17338 5.03121 4.65196 7.52649C3.08168 10.1019 2.97627 13.3099 4.37662 15.9874L4.55755 16.3402C4.79818 16.7905 4.84853 17.3183 4.68389 17.8451C4.42804 18.5246 4.21419 19.2192 4.04356 19.9249L4.138 19.557L4.53273 19.4541C4.66823 19.4168 4.80406 19.3775 4.9406 19.3361L5.35272 19.2055L5.77133 19.0617C6.21354 18.9399 6.6837 18.9681 7.14685 19.159C7.24408 19.2059 7.36471 19.271 7.51677 19.3569L7.93909 19.5993C7.94625 19.6012 7.95251 19.6029 7.95729 19.6042L7.94496 19.6026L8.23269 19.7468C11.2582 21.2076 14.8626 20.778 17.4587 18.6466L17.6788 18.4596C20.3626 16.1021 21.31 12.3357 20.0605 8.99235C18.8109 5.64861 15.6231 3.42182 12.0484 3.39463Z'
			fill='#96A6B8'
		/>
		<path
			className={"path path--default"}
			d='M11.2217 14.2832C11.2217 13.7204 11.2915 13.2718 11.4312 12.9375C11.5708 12.6032 11.8459 12.2371 12.2563 11.8394C12.6711 11.4373 12.9334 11.1517 13.0435 10.9824C13.2127 10.7243 13.2974 10.445 13.2974 10.1445C13.2974 9.74674 13.1979 9.44417 12.999 9.23682C12.8044 9.02523 12.5166 8.91943 12.1357 8.91943C11.7718 8.91943 11.4777 9.02311 11.2534 9.23047C11.0334 9.43359 10.9233 9.71077 10.9233 10.062H9.38086C9.38932 9.31299 9.64323 8.72054 10.1426 8.28467C10.6462 7.8488 11.3105 7.63086 12.1357 7.63086C12.9863 7.63086 13.6486 7.84668 14.1226 8.27832C14.6007 8.70996 14.8398 9.31299 14.8398 10.0874C14.8398 10.7772 14.5182 11.4564 13.875 12.125L13.0942 12.8931C12.8149 13.2104 12.6711 13.6738 12.6626 14.2832H11.2217ZM11.1138 16.2573C11.1138 16.0076 11.1921 15.8066 11.3486 15.6543C11.5052 15.4977 11.7168 15.4194 11.9834 15.4194C12.2542 15.4194 12.4679 15.4998 12.6245 15.6606C12.7811 15.8172 12.8594 16.0161 12.8594 16.2573C12.8594 16.4901 12.7832 16.6847 12.6309 16.8413C12.4785 16.9979 12.2627 17.0762 11.9834 17.0762C11.7041 17.0762 11.4883 16.9979 11.3359 16.8413C11.1878 16.6847 11.1138 16.4901 11.1138 16.2573Z'
			fill='#96A6B8'
		/>
	</IconMain>
);

const InfoIcon: FC = (): JSX.Element => (
	<IconMain width={24} height={24}>
		<path
			className={"path path--active"}
			d='M16.3399 1.91589C19.7299 1.91589 21.9999 4.29589 21.9999 7.83589V16.0069C21.9999 19.5369 19.7299 21.9159 16.3399 21.9159H7.66988C4.27988 21.9159 1.99988 19.5369 1.99988 16.0069V7.83589C1.99988 4.29589 4.27988 1.91589 7.66988 1.91589H16.3399ZM11.9899 10.3969C11.5099 10.3969 11.1199 10.7959 11.1199 11.2769V15.6969C11.1199 16.1769 11.5099 16.5669 11.9899 16.5669C12.4799 16.5669 12.8699 16.1769 12.8699 15.6969V11.2769C12.8699 10.7959 12.4799 10.3969 11.9899 10.3969ZM12.0099 7.22689C11.5199 7.22689 11.1299 7.61689 11.1299 8.10689C11.1299 8.58589 11.5199 8.97689 11.9899 8.97689C12.4899 8.97689 12.8799 8.58589 12.8799 8.10689C12.8799 7.61689 12.4899 7.22689 12.0099 7.22689Z'
			fill='#20AAFF'
		/>
		<path
			className={"path path--default"}
			d='M16.3346 2.00018C19.7384 2.00018 22.0006 4.42969 22.0006 7.91618V16.0842C22.0006 19.5708 19.7382 22.0002 16.3336 22.0002H7.66561C4.26198 22.0002 2.00061 19.5709 2.00061 16.0842V7.91618C2.00061 4.43278 4.26804 2.00018 7.66561 2.00018H16.3346ZM16.3346 3.50018H7.66561C5.12095 3.50018 3.50061 5.23856 3.50061 7.91618V16.0842C3.50061 18.7655 5.11537 20.5002 7.66561 20.5002H16.3336C18.885 20.5002 20.5006 18.7653 20.5006 16.0842V7.91618C20.5006 5.23515 18.8851 3.50018 16.3346 3.50018ZM11.9899 10.6271C12.3696 10.6271 12.6834 10.9092 12.7331 11.2753L12.7399 11.3771V15.7961C12.7399 16.2103 12.4041 16.5461 11.9899 16.5461C11.6102 16.5461 11.2964 16.2639 11.2468 15.8979L11.2399 15.7961V11.3771C11.2399 10.9629 11.5757 10.6271 11.9899 10.6271ZM11.9999 7.45428C12.4141 7.45428 12.7499 7.79007 12.7499 8.20428C12.7499 8.58398 12.4678 8.89777 12.1017 8.94744L11.9899 8.95428C11.5757 8.95428 11.2399 8.6185 11.2399 8.20428C11.2399 7.82459 11.5221 7.51079 11.8881 7.46113L11.9999 7.45428Z'
			fill='#96A6B8'
		/>
	</IconMain>
);

const AccountIcon: FC = (): JSX.Element => (
	<IconMain width={21} height={21}>
		<path
			className={"path path--active"}
			d='M14.9707 0.926409C16.3493 0.840248 17.7088 1.31892 18.7331 2.25712C19.6713 3.28147 20.15 4.6409 20.0734 6.02905V14.9706C20.1596 16.3588 19.6713 17.7182 18.7427 18.7426C17.7183 19.6808 16.3493 20.1594 14.9707 20.0733H6.02909C4.64093 20.1594 3.28149 19.6808 2.25713 18.7426C1.31892 17.7182 0.840247 16.3588 0.926409 14.9706V6.02905C0.840247 4.6409 1.31892 3.28147 2.25713 2.25712C3.28149 1.31892 4.64093 0.840248 6.02909 0.926409H14.9707ZM14.7218 5.30147C14.1283 4.70791 13.1709 4.70791 12.5774 5.30147L11.9359 5.95246C11.8402 6.04819 11.8402 6.21094 11.9359 6.30668C11.9359 6.30668 11.9547 6.32535 11.9882 6.3586L12.2237 6.59279C12.3599 6.72832 12.5299 6.89757 12.7006 7.06776L13.2795 7.6464C13.4003 7.76775 13.4805 7.84906 13.4868 7.85757C13.5922 7.97245 13.6592 8.12563 13.6592 8.29795C13.6592 8.64259 13.3815 8.9298 13.0273 8.9298C12.8646 8.9298 12.7114 8.86278 12.6061 8.75747L11.0073 7.16828C10.9307 7.0917 10.7967 7.0917 10.7201 7.16828L6.15354 11.7348C5.83762 12.0507 5.65572 12.472 5.64615 12.9219L5.58871 15.1908C5.58871 15.3153 5.627 15.4302 5.71316 15.5163C5.79932 15.6025 5.91421 15.6503 6.03866 15.6503H8.28843C8.74796 15.6503 9.18834 15.4684 9.52342 15.143L15.9664 8.68089C16.5504 8.08733 16.5504 7.12999 15.9664 6.54601L14.7218 5.30147Z'
			fill='#20AAFF'
		/>
		<path
			className={"path path--default"}
			d='M14.9707 0.926409C16.3493 0.840248 17.7088 1.31892 18.7331 2.25712C19.6713 3.28147 20.15 4.6409 20.0734 6.02905V14.9706C20.1596 16.3588 19.6713 17.7182 18.7427 18.7426C17.7183 19.6808 16.3493 20.1594 14.9707 20.0733H6.02909C4.64093 20.1594 3.28149 19.6808 2.25713 18.7426C1.31892 17.7182 0.840247 16.3588 0.926409 14.9706V6.02905C0.840247 4.6409 1.31892 3.28147 2.25713 2.25712C3.28149 1.31892 4.64093 0.840248 6.02909 0.926409H14.9707ZM14.7218 5.30147C14.1283 4.70791 13.1709 4.70791 12.5774 5.30147L11.9359 5.95246C11.8402 6.04819 11.8402 6.21094 11.9359 6.30668C11.9359 6.30668 11.9547 6.32535 11.9882 6.3586L12.2237 6.59279C12.3599 6.72832 12.5299 6.89757 12.7006 7.06776L13.2795 7.6464C13.4003 7.76775 13.4805 7.84906 13.4868 7.85757C13.5922 7.97245 13.6592 8.12563 13.6592 8.29795C13.6592 8.64259 13.3815 8.9298 13.0273 8.9298C12.8646 8.9298 12.7114 8.86278 12.6061 8.75747L11.0073 7.16828C10.9307 7.0917 10.7967 7.0917 10.7201 7.16828L6.15354 11.7348C5.83762 12.0507 5.65572 12.472 5.64615 12.9219L5.58871 15.1908C5.58871 15.3153 5.627 15.4302 5.71316 15.5163C5.79932 15.6025 5.91421 15.6503 6.03866 15.6503H8.28843C8.74796 15.6503 9.18834 15.4684 9.52342 15.143L15.9664 8.68089C16.5504 8.08733 16.5504 7.12999 15.9664 6.54601L14.7218 5.30147Z'
			fill='#96A6B8'
		/>
	</IconMain>
);

const ReferalIcon: FC = (): JSX.Element => (
	<IconMain width={25} height={25}>
		<path
			className={"path path--active"}
			d='M10.7311 10.9496L13.7346 14.0205M12.2787 6.74255H16.9357M12.187 18.2276H7.54547'
			stroke='#20AAFF'
			strokeWidth='1.00866'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			className={"path path--active"}
			d='M8.68312 6.38951C8.68312 7.55656 7.75781 8.50265 6.61637 8.50265C5.47494 8.50265 4.54962 7.55656 4.54962 6.38951C4.54962 5.22245 5.47494 4.27636 6.61637 4.27636C7.75781 4.27636 8.68312 5.22245 8.68312 6.38951Z'
			fill='#20AAFF'
			stroke='#20AAFF'
			strokeWidth='1.00866'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			className={"path path--active"}
			d='M20.4203 17.8745C20.4203 19.0415 19.495 19.9876 18.3536 19.9876C17.2121 19.9876 16.2868 19.0415 16.2868 17.8745C16.2868 16.7074 17.2121 15.7613 18.3536 15.7613C19.495 15.7613 20.4203 16.7074 20.4203 17.8745Z'
			fill='#20AAFF'
			stroke='#20AAFF'
			strokeWidth='1.00866'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			className={"path path--active"}
			d='M3.75475 11.4284V11.6847C4.59281 12.1931 5.57128 12.485 6.61642 12.485C7.66156 12.485 8.64002 12.1931 9.47808 11.6847V11.4284C9.47808 9.81248 8.19685 8.50253 6.61642 8.50253C5.03593 8.50253 3.75475 9.81252 3.75475 11.4284Z'
			fill='#20AAFF'
		/>
		<path
			className={"path path--active"}
			d='M6.61642 12.485C9.71828 12.485 12.2328 9.91401 12.2328 6.74251C12.2328 3.57102 9.71828 1.00002 6.61642 1.00002C3.51456 1.00002 1 3.57102 1 6.74251C1 9.91401 3.51456 12.485 6.61642 12.485ZM6.61642 12.485C5.57128 12.485 4.59281 12.1931 3.75475 11.6847V11.4284C3.75475 9.81252 5.03593 8.50253 6.61642 8.50253C8.19685 8.50253 9.47808 9.81248 9.47808 11.4284V11.6847C8.64002 12.1931 7.66156 12.485 6.61642 12.485Z'
			stroke='#20AAFF'
			fill='transparent'
			strokeWidth='1.00866'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			className={"path path--active"}
			d='M15.4919 22.9135V23.1698C16.3299 23.6781 17.3084 23.97 18.3535 23.97C19.3987 23.97 20.3771 23.6782 21.2152 23.1698V22.9135C21.2152 21.2975 19.934 19.9875 18.3535 19.9875C16.7731 19.9875 15.4919 21.2975 15.4919 22.9135Z'
			fill='#20AAFF'
		/>
		<path
			className={"path path--active"}
			d='M18.3535 23.97C21.4554 23.97 23.97 21.399 23.97 18.2275C23.97 15.056 21.4554 12.485 18.3535 12.485C15.2517 12.485 12.7371 15.056 12.7371 18.2275C12.7371 21.399 15.2517 23.97 18.3535 23.97ZM18.3535 23.97C17.3084 23.97 16.3299 23.6781 15.4919 23.1698V22.9135C15.4919 21.2975 16.7731 19.9875 18.3535 19.9875C19.934 19.9875 21.2152 21.2975 21.2152 22.9135V23.1698C20.3771 23.6782 19.3987 23.97 18.3535 23.97Z'
			stroke='#20AAFF'
			fill='transparent'
			strokeWidth='1.00866'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			className={"path path--active"}
			d='M4.24203 21.5423C6.03255 21.5423 7.48405 20.0582 7.48405 18.2275C7.48405 16.3968 6.03255 14.9127 4.24203 14.9127C2.4515 14.9127 1 16.3968 1 18.2275C1 20.0582 2.4515 21.5423 4.24203 21.5423Z'
			fill='#20AAFF'
		/>
		<path
			className={"path path--active"}
			d='M20.2236 10.0573C22.0141 10.0573 23.4656 8.57323 23.4656 6.74251C23.4656 4.91179 22.0141 3.4277 20.2236 3.4277C18.4331 3.4277 16.9816 4.91179 16.9816 6.74251C16.9816 8.57323 18.4331 10.0573 20.2236 10.0573Z'
			fill='#20AAFF'
		/>

		<path
			className={"path path--default"}
			d='M10.7311 10.9496L13.7346 14.0205M12.2787 6.74255H16.9357M12.187 18.2276H7.54547'
			stroke='#96A6B8'
			strokeWidth='1.00866'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			className={"path path--default"}
			d='M8.68312 6.38951C8.68312 7.55656 7.75781 8.50265 6.61637 8.50265C5.47494 8.50265 4.54962 7.55656 4.54962 6.38951C4.54962 5.22245 5.47494 4.27636 6.61637 4.27636C7.75781 4.27636 8.68312 5.22245 8.68312 6.38951Z'
			fill='#96A6B8'
			stroke='#96A6B8'
			strokeWidth='1.00866'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			className={"path path--default"}
			d='M20.4203 17.8745C20.4203 19.0415 19.495 19.9876 18.3536 19.9876C17.2121 19.9876 16.2868 19.0415 16.2868 17.8745C16.2868 16.7074 17.2121 15.7613 18.3536 15.7613C19.495 15.7613 20.4203 16.7074 20.4203 17.8745Z'
			fill='#96A6B8'
			stroke='#96A6B8'
			strokeWidth='1.00866'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			className={"path path--default"}
			d='M3.75475 11.4284V11.6847C4.59281 12.1931 5.57128 12.485 6.61642 12.485C7.66156 12.485 8.64002 12.1931 9.47808 11.6847V11.4284C9.47808 9.81248 8.19685 8.50253 6.61642 8.50253C5.03593 8.50253 3.75475 9.81252 3.75475 11.4284Z'
			fill='#96A6B8'
		/>
		<path
			className={"path path--default"}
			d='M6.61642 12.485C9.71828 12.485 12.2328 9.91401 12.2328 6.74251C12.2328 3.57102 9.71828 1.00002 6.61642 1.00002C3.51456 1.00002 1 3.57102 1 6.74251C1 9.91401 3.51456 12.485 6.61642 12.485ZM6.61642 12.485C5.57128 12.485 4.59281 12.1931 3.75475 11.6847V11.4284C3.75475 9.81252 5.03593 8.50253 6.61642 8.50253C8.19685 8.50253 9.47808 9.81248 9.47808 11.4284V11.6847C8.64002 12.1931 7.66156 12.485 6.61642 12.485Z'
			stroke='#96A6B8'
			fill='transparent'
			strokeWidth='1.00866'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			className={"path path--default"}
			d='M15.4919 22.9135V23.1698C16.3299 23.6781 17.3084 23.97 18.3535 23.97C19.3987 23.97 20.3771 23.6782 21.2152 23.1698V22.9135C21.2152 21.2975 19.934 19.9875 18.3535 19.9875C16.7731 19.9875 15.4919 21.2975 15.4919 22.9135Z'
			fill='#96A6B8'
		/>
		<path
			className={"path path--default"}
			d='M18.3535 23.97C21.4554 23.97 23.97 21.399 23.97 18.2275C23.97 15.056 21.4554 12.485 18.3535 12.485C15.2517 12.485 12.7371 15.056 12.7371 18.2275C12.7371 21.399 15.2517 23.97 18.3535 23.97ZM18.3535 23.97C17.3084 23.97 16.3299 23.6781 15.4919 23.1698V22.9135C15.4919 21.2975 16.7731 19.9875 18.3535 19.9875C19.934 19.9875 21.2152 21.2975 21.2152 22.9135V23.1698C20.3771 23.6782 19.3987 23.97 18.3535 23.97Z'
			stroke='#96A6B8'
			fill='transparent'
			strokeWidth='1.00866'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			className={"path path--default"}
			d='M4.24203 21.5423C6.03255 21.5423 7.48405 20.0582 7.48405 18.2275C7.48405 16.3968 6.03255 14.9127 4.24203 14.9127C2.4515 14.9127 1 16.3968 1 18.2275C1 20.0582 2.4515 21.5423 4.24203 21.5423Z'
			fill='#96A6B8'
		/>
		<path
			className={"path path--default"}
			d='M20.2236 10.0573C22.0141 10.0573 23.4656 8.57323 23.4656 6.74251C23.4656 4.91179 22.0141 3.4277 20.2236 3.4277C18.4331 3.4277 16.9816 4.91179 16.9816 6.74251C16.9816 8.57323 18.4331 10.0573 20.2236 10.0573Z'
			fill='#96A6B8'
		/>
	</IconMain>
);

export {
	ProfileIcon,
	DocumentIcon,
	UsersIcon,
	ChatIcon,
	InfoIcon,
	AccountIcon,
	ReferalIcon,
};
