import { IVideo } from "@common/types";
import React, { ReactElement } from "react";
import BoxIcon from "@components/common/Icon/BoxIcon";
import { RGB_COLORS } from "@common/constants";
import { ReactComponent as WorkIcon } from "@icons/work-colored.svg";
import { ReactComponent as SwapIcon } from "@icons/swap-colored.svg";
import { ReactComponent as CheckSquareIcon } from "@icons/check-square-colored.svg";
import { ReactComponent as DangerCircleIcon } from "@icons/danger-circle-colored.svg";
import VideoPlayer from "@components/common/Video/VideoPlayer";
import classNames from "classnames";
import LazyImage from "@components/common/LazyImage/LazyImage";
import { Ratio } from "react-bootstrap";
import { ReactComponent as AssetPlaceholderIcon } from "@icons/asset-placeholder.svg";
import { IBorrower } from "@features/borrower/types";
import { Link } from "react-router-dom";

interface IProjectDetailStatisticItem {
	key: string;
	value: string | undefined;
	icon: ReactElement;
}

export const generateBorrowerStatisticsData = ({
	openProjectCount,
	runProjectCount,
	succeededProjectCount,
	failedProjectCount,
}: IBorrower): IProjectDetailStatisticItem[] => {
	return [
		{
			key: "Всего открыто проектов",
			value: openProjectCount.toString(),
			icon: (
				<BoxIcon bgColor={RGB_COLORS.blue} size={"sm"}>
					<WorkIcon />
				</BoxIcon>
			),
		},
		{
			key: "Проектов в процессе",
			value: runProjectCount.toString(),
			icon: (
				<BoxIcon bgColor={RGB_COLORS.yellow} size={"sm"}>
					<SwapIcon />
				</BoxIcon>
			),
		},
		{
			key: "Завершенных в срок проектов",
			value: succeededProjectCount.toString(),
			icon: (
				<BoxIcon bgColor={RGB_COLORS.green} size={"sm"}>
					<CheckSquareIcon />
				</BoxIcon>
			),
		},
		{
			key: "Проектов с нарушением сроков",
			value: failedProjectCount.toString(),
			icon: (
				<BoxIcon bgColor={RGB_COLORS.red} size={"sm"}>
					<DangerCircleIcon />
				</BoxIcon>
			),
		},
	];
};

export const defineProjectMedia = (data: {
	video: IVideo | null;
	image: string;
	onVideoPlay?: (video: IVideo) => void;
	videoClassName?: string;
	imageClassName?: string;
	placeholderClassName?: string;
	linkTo?: string;
}): JSX.Element => {
	const {
		video,
		image,
		onVideoPlay,
		videoClassName,
		imageClassName,
		placeholderClassName,
		linkTo,
	} = data;

	const linkWrapper = (children: ReactElement): JSX.Element => {
		return linkTo ? (
			<Link className={"d-block w-100 h-100 text-center"} to={linkTo}>
				{children}
			</Link>
		) : (
			children
		);
	};

	if (video !== null) {
		return (
			<VideoPlayer
				className={classNames(videoClassName)}
				video={video}
				onPlayClick={(video) => onVideoPlay && onVideoPlay(video)}
			/>
		);
	} else if (Boolean(image)) {
		return linkWrapper(
			<LazyImage
				className={imageClassName}
				src={image}
				alt='Project image'
				aspectRatio={"16x9"}
			/>,
		);
	}

	return (
		<Ratio className={placeholderClassName} aspectRatio={"16x9"}>
			{linkWrapper(<AssetPlaceholderIcon />)}
		</Ratio>
	);
};
