import React, { FC, useContext, useEffect, useMemo } from "react";
import {
	IProfileTab,
	IRedirectToBorrowerConditionsState,
	profileContext,
} from "@context/profileContext";
import ProfileTabsContent from "@components/profile/reused/Tabs/ProfileTabsContent";
import BorrowerAccountData from "@components/borrower/Views/Account/Data/AccountData";
import BorrowerAccountDocuments from "@components/borrower/Views/Account/Documents/AccountDocuments";
import BorrowerAccountConditions from "@components/borrower/Views/Account/Conditions/AccountConditions";
import ProfileContainer from "@components/profile/Views/Container/Container";
import { BorrowerPagesTitleEnum } from "@common/types";
import { borrowerContext, DocumentItemType } from "@context/borrowerContext";
import borrowerApi from "@api/borrowerApi";
import { useLocation } from "react-router-dom";
import useMounted from "@hooks/useMounted";
import { BORROWER_REQUIRED_FOR_CREDIT_HISTORY_FIELDS_NAMES } from "@components/borrower/Views/Account/constants";
import { IBorrowerProfile } from "@features/user/types";
import { useAppSelector } from "@hooks/useAppSelector";

export enum BorrowerAccountTabsKeys {
	DATA = "data",
	DOCUMENTS = "documents",
	CONDITIONS = "conditions",
}

export const borrowerAccountTabs: IProfileTab[] = [
	{
		isActive: false,
		key: BorrowerAccountTabsKeys.DATA,
		name: "Личные данные",
	},
	{
		isActive: false,
		key: BorrowerAccountTabsKeys.DOCUMENTS,
		name: "Документы",
	},
	{
		isActive: false,
		key: BorrowerAccountTabsKeys.CONDITIONS,
		name: "Условия займа",
	},
];

export const BorrowerAccount: FC = () => {
	const { setTabs, activeTab } = useContext(profileContext),
		{ setDocuments } = useContext(borrowerContext),
		[getProfileData] = borrowerApi.useLazyGetProfileQuery(),
		location = useLocation(),
		{ borrowerProfile } = useAppSelector((state) => state.user),
		conditionsAllowed = useMemo(() => {
			return (
				BORROWER_REQUIRED_FOR_CREDIT_HISTORY_FIELDS_NAMES.filter((key) => {
					return !Boolean(borrowerProfile[key as keyof IBorrowerProfile]);
				}).length === 0
			);
		}, [borrowerProfile]);
	useMounted({
		onResolve() {
			getProfileData();
		},
		dependencies: [activeTab],
	});

	useEffect(() => {
		const needRedirect = Boolean(
			(location.state as IRedirectToBorrowerConditionsState)?.needRedirect,
		);

		let currentTabs = borrowerAccountTabs.map((t) => ({
			...t,
			isActive:
				t.key ===
				(conditionsAllowed
					? BorrowerAccountTabsKeys.CONDITIONS
					: BorrowerAccountTabsKeys.DATA),
		}));

		if (!conditionsAllowed) {
			currentTabs = currentTabs.filter((tab) => {
				if (tab.key === BorrowerAccountTabsKeys.CONDITIONS) {
					return conditionsAllowed ? tab : false;
				} else {
					return true;
				}
			});
		}

		const currentTab = currentTabs.find((t) => t.isActive);

		if (currentTab) {
			setTabs(currentTabs, needRedirect ? currentTabs.indexOf(currentTab) : 0);
		}
	}, [location.state, conditionsAllowed]);

	useEffect(() => {
		return () => {
			setTabs([]);
			setDocuments({} as DocumentItemType);
		};
	}, []);

	return (
		<ProfileContainer title={BorrowerPagesTitleEnum.ACCOUNT}>
			<ProfileTabsContent
				items={{
					[BorrowerAccountTabsKeys.DATA]: <BorrowerAccountData />,
					[BorrowerAccountTabsKeys.DOCUMENTS]: <BorrowerAccountDocuments />,
					[BorrowerAccountTabsKeys.CONDITIONS]: <BorrowerAccountConditions />,
				}}
			/>
		</ProfileContainer>
	);
};
