import React, { FC, useEffect } from "react";
import { useAppSelector } from "@hooks/useAppSelector";
import { useAppActions } from "@hooks/useAppActions";
import { AuthRolesEnum } from "@features/auth/types";
import styles from "./roleToggler.module.scss";
import classNames from "classnames";
import useBoolean from "@hooks/useBoolean";
import { useLocation } from "react-router-dom";

interface IRoleTogglerProps {
	className?: string;
}

const RoleToggler: FC<IRoleTogglerProps> = ({ className }) => {
	const location = useLocation();

	const { role } = useAppSelector((state) => state.auth);
	const { setRole } = useAppActions();

	const { value: isInvestor, setValue: setIsInvestor } = useBoolean(
		(role === AuthRolesEnum.INVESTOR || role === null) &&
			!location.pathname.includes("borrower"),
	);

	useEffect(() => {
		setRole(isInvestor ? AuthRolesEnum.INVESTOR : AuthRolesEnum.BORROWER);
	}, [isInvestor]);

	return (
		<div
			className={classNames(
				styles.root,
				{ "is-active": !isInvestor },
				className,
			)}>
			<label className='row gx-0'>
				<input
					type='checkbox'
					name='authRole'
					className='visually-hidden'
					checked={role === AuthRolesEnum.INVESTOR}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						setIsInvestor(event.target.checked)
					}
				/>
				<span className='col-6'>
					<span
						className={classNames(styles.control, { "is-active": isInvestor })}>
						Инвестор
					</span>
				</span>
				<span className='col-6'>
					<span
						className={classNames(styles.control, {
							"is-active": !isInvestor,
						})}>
						Заемщик
					</span>
				</span>
			</label>
		</div>
	);
};

export default RoleToggler;
