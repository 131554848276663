import React, { FC, useMemo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import classNames from "classnames";
import { HeadingVariant } from "@components/ui/Heading/Heading.props";
import { Heading, Button } from "@components/ui";
import {
	ButtonTypesEnum,
	ButtonVariant,
	ButtonIconsVariant,
} from "@components/ui/Button/Button.props";
import { ProfilePagesTitleEnum } from "@common/types";
import ProfileContainer from "@components/profile/Views/Container/Container";
import referalApi from "@api/referalApi";
import { Spinner } from "react-bootstrap";

import styles from "./referal.module.scss";
import { TOAST_AUTO_HIDE_DELAY } from "@common/constants";
import ProfileSection from "@components/profile/Section/ProfileSection";
import illustration from "@images/referal.svg";
import ToastErrorMessage from "@components/common/Toast/ToastErrorMessage";
import { RewardList } from "./RewardList";
import { UserList } from "./UserList";

export const ProfileReferal: FC = () => {
	const getLink = referalApi.useGetLinkQuery();
	const getOferta = referalApi.useGetOfertaQuery();

	const copied = () => {
		navigator.clipboard
			.writeText(getLink.data?.link || "")
			.then(() => {
				toast.success(<ToastErrorMessage message='Текст скопирован!' />, {
					autoClose: TOAST_AUTO_HIDE_DELAY,
					position: toast.POSITION.TOP_LEFT,
				});
			})
			.catch((err) =>
				toast.error(
					<ToastErrorMessage message='Что-то пошло не так попробуйте снова' />,
					{
						autoClose: TOAST_AUTO_HIDE_DELAY,
						position: toast.POSITION.TOP_LEFT,
					},
				),
			);
	};

	return (
		<ProfileContainer title={ProfilePagesTitleEnum.REFERAL}>
			<ProfileSection title='Увеличивайте свой капитал!' limitMinHeight>
				<div className={styles.container}>
					<div className={styles.blockWithImg}>
						<div className={styles.blockWrap}>
							<div className={styles.textWrap}>
								<p>
									Приглашайте инвесторов и заёмщиков к нам на платформу
									и вы будете получать:
								</p>
								<ul>
									<li>
										— 0,6% от суммы всех сделок займа приглашенного инвестора;
									</li>
									<li>
										— 0,6% от суммы всех выданных займов привлеченного заемщика.
									</li>
								</ul>
								<p>
									Вознаграждение переводится на баланс вашего личного кабинета
									один раз в месяц в период действия реферальной программы.
									Если Реферал является физическим лицом, то вознаграждение
									выплачивается за минусом удержанного НДФЛ.
								</p>
								<a
									href={getOferta.data?.url ?? "#"}
									className={styles.link}
									target='_blank'
									rel='noreferrer'>
									Подробные условия в оферте
								</a>
							</div>
							<div className={styles.copyWrap}>
								{getLink.isFetching ? (
									<Spinner
										className={"spinner--centered"}
										animation='border'
										variant='primary'
									/>
								) : (
									<>
										<Heading
											tag={"h3"}
											className={classNames(styles.heading, "mb-0")}
											variant={HeadingVariant.middle}>
											Реферальная ссылка
										</Heading>
										<Button
											variant={ButtonVariant.outlined}
											as={ButtonTypesEnum.BUTTON}
											icon={ButtonIconsVariant.copy}
											className={styles.buttonCopy}
											onClick={copied}>
											{getLink.data?.link}
										</Button>
									</>
								)}
							</div>
						</div>
						<div className={styles.imgWrapper}>
							<img src={illustration} alt='illustration' />
						</div>
					</div>
					<UserList />
					<RewardList />
				</div>
			</ProfileSection>
		</ProfileContainer>
	);
};
