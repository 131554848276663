import React from "react";

export interface IRoute {
	path: string;
	Component: React.ComponentType;
	exact?: boolean;
}

export enum RouteNamesEnum {
	AUTH = "/auth",
	INVESTOR = "/investor",
	BORROWER = "/borrower",
	UI_KIT = "/ui-kit",
}

export enum InvestorRouteNamesEnum {
	CABINET = "/cabinet",
	PROJECTS = "/projects",
	PROJECT = "/projects/:id",
	CLUB = "/club",
	QUESTIONS = "/questions",
	ABOUT = "/about",
	DATA = "/data",
	HISTORY = "/history",
	REQUISITES = "/requisites",
	ANALYTICS = "/analytics",
	QUALIFICATION = "/qualification",
	REFERAL = "/referal",
}

export enum BorrowerRouteNamesEnum {
	ABOUT = "/about",
	ACCOUNT = "/account",
	CABINET = "/cabinet",
	LOANS = "/cabinet/loans",
	LOAN = "/cabinet/loans/:id",
	BIDS = "/cabinet/bids",
	BID = "/cabinet/bids/:id",
	QUESTIONS = "/questions",
}

export enum AuthRouteNamesEnum {
	CHECK_PHONE = "/phone-check",
	LOGIN = "/login",
	REGISTRATION_REQUEST = "/registration-request",
	REGISTRATION_CONFIRM = "/registration-confirm",
	REGISTRATION_PASSWORD = "/registration-password",
	RECOVER_PASSWORD_REQUEST = "/recover-password-request",
	RECOVER_PASSWORD_CONFIRM = "/recover-password-confirm",
	CHANGE_PASSWORD = "/change-password",
}
