import React, { FC, useEffect, useState } from "react";
import styles from "./appWrapper.module.scss";
import { Spinner } from "react-bootstrap";
import authApi from "@api/authApi";
import { useAppActions } from "@hooks/useAppActions";
import { useHistory, useLocation } from "react-router-dom";
import {
	AuthRouteNamesEnum,
	BorrowerRouteNamesEnum,
	InvestorRouteNamesEnum,
	RouteNamesEnum,
} from "@app/router";
import { useRedirectToHomePage } from "@hooks/useRedirectToHomePage";

const AppWrapper: FC = (props) => {
	const [checkAuthorized] = authApi.useIsAuthorizedMutation(),
		{ setAuthorized, setToken, setRole } = useAppActions(),
		history = useHistory(),
		location = useLocation(),
		{ baseHomeRoute } = useRedirectToHomePage(),
		[loading, setLoading] = useState(true);
	useEffect(() => {
		setLoading(true);
		checkAuthorized()
			.unwrap()
			.then((result) => {
				const { isAuthorized, role } = result;
				if (role) setRole(role);
				setAuthorized(isAuthorized);

				if (isAuthorized) {
					const routePaths: string[] = [
						...Object.values(AuthRouteNamesEnum).map(
							(r) => RouteNamesEnum.AUTH + r,
						),
						RouteNamesEnum.AUTH,

						...Object.values(InvestorRouteNamesEnum).map(
							(r) => RouteNamesEnum.INVESTOR + r,
						),
						RouteNamesEnum.INVESTOR,

						...Object.values(BorrowerRouteNamesEnum).map((r) => {
							return RouteNamesEnum.BORROWER + r;
						}),
						RouteNamesEnum.BORROWER,
					];

					if (location.pathname === "/") {
						baseHomeRoute && history.push(baseHomeRoute);
						return;
					}

					const finedRoute = routePaths.find((r) =>
						location.pathname.includes(r),
					);

					switch (finedRoute) {
						case RouteNamesEnum.AUTH:
						case RouteNamesEnum.INVESTOR:
							history.push(
								RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.QUESTIONS,
							);
							break;
						case RouteNamesEnum.BORROWER:
							history.push(
								RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.ACCOUNT,
							);
							break;
						default:
							finedRoute && history.push(location.pathname);
					}
				} else {
					const path = location.pathname.includes("borrower")
						? RouteNamesEnum.AUTH +
						  RouteNamesEnum.BORROWER +
						  AuthRouteNamesEnum.CHECK_PHONE
						: RouteNamesEnum.AUTH + AuthRouteNamesEnum.CHECK_PHONE;
					if (location.pathname !== path) history.push(path);
				}

				setLoading(false);
			})
			.catch((e) => {
				if (e.status === 401) setToken(null);
				history.push(RouteNamesEnum.AUTH + AuthRouteNamesEnum.CHECK_PHONE);
				setLoading(false);
			});
	}, []);

	return (
		<div {...props} className={styles.root}>
			{loading ? (
				<Spinner
					className={"spinner--centered"}
					animation='border'
					variant='primary'
				/>
			) : (
				props.children
			)}
		</div>
	);
};

export default AppWrapper;
