import React from "react";
import type { LinkProps } from "react-router-dom";

export enum ButtonIconsVariant {
	logout = "logout",
	notify = "notification",
	arrowRight = "arrow-right",
	arrowUp = "arrow-up",
	copy = "copy",
}

export enum ButtonVariant {
	contained = "contained",
	outlined = "outlined",
	icon = "icon",
	danger = "danger",
	success = "success",
}

export enum ButtonTypesEnum {
	BUTTON = "button",
	LINK = "link",
	EXTERNAL = "external",
}

export interface IButtonBaseProps {
	children?: React.ReactNode;
	className?: string;
	variant: ButtonVariant;
	icon?: ButtonIconsVariant;
	isLoading?: boolean;
	wrapperClassName?: string;
}

type ButtonAsButtonType = IButtonBaseProps &
	Omit<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		keyof IButtonBaseProps
	> & {
		as: ButtonTypesEnum.BUTTON;
	};

type ButtonAsLinkType = IButtonBaseProps &
	Omit<LinkProps, keyof IButtonBaseProps> & {
		as: ButtonTypesEnum.LINK;
	};

type ButtonAsExternalType = IButtonBaseProps &
	Omit<
		React.AnchorHTMLAttributes<HTMLAnchorElement>,
		keyof IButtonBaseProps
	> & {
		as: ButtonTypesEnum.EXTERNAL;
	};

export type ButtonProps =
	| ButtonAsButtonType
	| ButtonAsLinkType
	| ButtonAsExternalType;
